import React from 'react';

import './modules.css'

export default function Footer() {

  return (   
        <div class="footer_container">
            <p class="copyright">Copyright © 2022<span class="thisYear"></span> mame/All Rights Reserved.</p>
        </div>
  );
}